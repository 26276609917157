@use 'styles/config' as *;

.nav {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  color: #fff;
  // background: linear-gradient(180deg, rgba(#000, 0.4), transparent);

  @media (min-width: $min-960) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
  }

  &__nav {
    @include containerNoLimit();
    padding-left: 4rem !important;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding-top: 2rem;
    padding-bottom: 4rem;
    color: $color-dark;
    background: transparent;

    :global(body.white) & {
      color: $color-dark;
      background: #fff;
    }

    :global(body.image) & {
      color: $color-dark;
      background: transparent;
    }

    :global(body.brand) & {
      color: $color-dark;
      background: brand();
    }

    :global(body.dark) & {
      color: #fff;
      background: $color-dark;
    }
  }

  &__toggle {
    @include reset-button;
    color: inherit;
    transition: color 200ms;
    cursor: pointer;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-top: 0.4rem;
    }

    &:hover {
      &:hover,
      &.isActive {
        color: brand();
      }
    }

    :global(body.dark) & {
      color: brand();

      &:hover,
      &.isActive {
        color: #fff;
      }
    }

    :global(body.brand) & {
      &:hover,
      &.isActive {
        color: #fff;
      }
    }

    .isOpen &,
    :global(body.image) & {
      color: brand();

      &:hover,
      &.isActive {
        color: #fff;
      }

      :global(.almatica) & {
        color: #fff;

        &:hover,
        &.isActive {
          color: brand();
        }
      }
    }
  }

  &__dropdown {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 0;
    overflow: hidden;
    background: #111;
  }

  &__container {
    @include containerNoLimit();
    // @include container();
  }

  &__top {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;

    height: 3.8rem;

    font-size: 1.2rem;
    font-weight: 500;

    z-index: 1;

    a,
    button {
      @include reset-anchor;
      display: flex;
      align-items: center;
      color: inherit;

      transition: color 200ms;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      .isOpen & {
        transition: color 200ms;
      }

      &:hover {
        &:hover,
        &.isActive {
          color: brand();
        }
      }

      :global(body.dark) & {
        color: brand();

        &:hover,
        &.isActive {
          color: #fff;
        }
      }

      :global(body.brand) & {
        &:hover,
        &.isActive {
          color: #fff;
        }
      }

      .isOpen &,
      :global(body.image) & {
        color: brand();

        &:hover,
        &.isActive {
          color: #fff;
        }

        :global(.almatica) & {
          color: #fff;

          &:hover,
          &.isActive {
            color: brand();
          }
        }
      }
    }
  }

  &__main {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;

    height: 3rem;

    font-size: responsive(1.6rem, 1.8rem, 108rem, 128rem);
    font-weight: 500;
  }
}

.search {
  opacity: 0;
  height: 0;
  padding: 0;
  overflow: hidden;

  &.isActive {
    height: auto;
    padding: 13rem 0 6rem;
    opacity: 1;
  }

  &__layout {
    display: grid;
    grid-template-columns: clamp(20rem, 35vw, 100%) auto minmax(0, 50%);
    justify-items: flex-end;

    > * {
      grid-column: 3 / 4;
    }
  }
}

.menu {
  opacity: 0;
  height: 0;
  padding: 0;
  overflow: hidden;

  &.isActive {
    height: auto;
    padding: 13rem 0 6rem;
    opacity: 1;
  }

  &__layout {
    display: grid;
    // gap: 3rem;
    grid-template-columns: clamp(20rem, 35vw, 100%) auto minmax(0, 50%);
    align-items: center;
  }

  &__image {
    overflow: hidden;
    height: min(30rem, 20vw);
    max-width: 60rem;
    background-size: cover;
    background-position: center center;
  }

  &__navigation {
    grid-column: 3 / 4;
    display: grid;
    column-gap: 3rem;
    row-gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .single & {
      grid-template-columns: minmax(0, 1fr);
    }
  }
}

.item {
  display: block;
  width: 100%;

  line-height: 1.5;
  font-weight: 400;
  font-size: 1.8rem;
  color: inherit;

  transition: color 600ms;

  .isOpen & {
    transition: color 200ms;
  }

  &__link {
    @include reset-anchor;
    display: block;
    padding: 0.7rem 0;

    &:hover {
      color: brand();
    }

    &.isActive {
      color: brand();
    }
  }
}

.sectionItem {
  @include reset-button;
  @include reset-anchor;
  display: flex;
  align-items: center;
  color: inherit;
  cursor: pointer;

  transition: color 200ms;

  &:not(:last-child) {
    margin-right: 2rem;

    @media (min-width: $min-1080) {
      margin-right: 3rem;
    }
  }

  .isOpen & {
    transition: color 200ms;
  }

  &:hover,
  &.isActive {
    color: brand();
  }

  :global(body.image) & {
    color: #fff;

    &:hover,
    &.isActive {
      color: brand();
    }
  }

  :global(body.brand) & {
    &:hover,
    &.isActive {
      color: #fff;
    }
  }

  body .isOpen & {
    color: #fff;

    &:hover,
    &.isActive {
      color: brand();
    }
  }
}
